/** @module application */

/**
 * Manages filtering items.
 *
 * @returns {Promise<void>} Resolves immediately if no filters need to be managed, or once the required script loaded.
 */
async function filters() {
    const filters = document.querySelectorAll('[data-filter]');

    if (filters.length) {
        const { initFilters } = await import(/* webpackMode: "lazy" */ './filters.async');

        initFilters(filters);
    }
}

async function searches() {
    const searches = document.querySelectorAll('[data-search]');

    if (searches.length) {
        const { initSearches } = await import(/* webpackMode: "lazy" */ './searches.async');

        initSearches(searches);
    }
}

async function mobileFilter() {
    const mobileFilterToggle = document.querySelector('[data-mobile-filter-toggle]');

    if (mobileFilterToggle) {
        const { initMobileFilter } = await import(/* webpackMode: "lazy" */ './mobile-filter.async');

        initMobileFilter(mobileFilterToggle);
    }
}

export { filters, searches, mobileFilter };
