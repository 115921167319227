export async function swiper() {
    const swiperContainer = document.querySelectorAll(
        '.swiper-container[data-enabled="true"]:not(.swiper-container--mix-and-match)',
    );

    if (swiperContainer.length) {
        let { initSwiper } = await import(/* webpackMode: "lazy" */ './swiper.async');

        initSwiper(swiperContainer);
    }
}
