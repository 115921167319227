/** @module add-to-cart */

import { liveEvents } from '../../../../assets/js/application';

function toggleAddToCartLoadingIndicator(show, $button) {
    /* eslint-disable jquery/no-data, jquery/no-class */
    if ($button.data('hasIndicator') !== true) {
        $button.append(`<span class="button__loading-indicator">
    <span class="loading-indicator-item"></span>
    </span>`);
        $button.data('hasIndicator', true);
    }

    if (show) {
        $button.addClass('is-loading');
    } else {
        $button.removeClass('is-loading');
    }
    /* eslint-enable */
}

function toggleMiniCart(
    shoppingCart = document.querySelector('[data-header-shop-nav-shopping-cart]'),
    shoppingCartIsOpenClass = 'header__shop-nav__shopping-cart--open',
    shoppingCartMiniMobile = document.querySelector('[data-shopping-cart-mini-mobile]'),
    shoppingCartMiniMobileIsOpenClass = 'shopping-cart__mini__mobile--open',
) {
    const headroom = document.querySelector('.headroom');

    if (shoppingCart) {
        shoppingCart.classList.add(shoppingCartIsOpenClass);
    }

    if (shoppingCartMiniMobile) {
        // open mini-cart (only visible on mobile)
        shoppingCartMiniMobile.classList.add(shoppingCartMiniMobileIsOpenClass);
    }

    setTimeout(() => {
        shoppingCartMiniMobile.classList.remove(shoppingCartMiniMobileIsOpenClass);
    }, 3000);

    // toggle headroom to show mini-cart
    if (headroom) {
        if (headroom.classList.contains('headroom--not-top')) {
            headroom.classList.remove('headroom--unfixed');
            headroom.classList.add('headroom--fixed');
        }
    }
}

/**
 * Initializes ajax add to cart functionality for WooCommerce
 *
 * Should not be called directly.
 * @private
 * @returns {Promise<void>}
 */
async function initAddToCart() {
    const events = await liveEvents();

    let ajaxAddToCartLinks = document.querySelectorAll('[data-ajax-add-to-cart]'),
        regularAddToCartSubmitButton = document.querySelectorAll('[data-simple-add-to-cart]'),
        inputQuantity = document.querySelectorAll('[data-add-to-cart-quantity]');

    if (ajaxAddToCartLinks.length > 0) {
        // toggle loading indicator for ajax add to cart button (jQuery needed because of woocommerce custom jquery event)
        jQuery(document.body).on('adding_to_cart', (event, $button) => {
            toggleAddToCartLoadingIndicator(true, $button);
        });

        jQuery(document.body).on('added_to_cart', (event, fragments, cartHash, $button) => {
            toggleMiniCart();
            toggleAddToCartLoadingIndicator(false, $button);

            inputQuantity.forEach((el) => {
                // reset quantity field
                el.value = 1;
            });
        });

        // hide regular submit button when JS is active
        regularAddToCartSubmitButton.forEach((submit) => {
            submit.style.display = 'none';
        });

        ajaxAddToCartLinks.forEach((el) => {
            el.style.display = 'inline-flex';
        });

        // set data-quantity of [data-ajax-add-to-cart] to current value in the quantity <input> field
        events.addEventListener('[data-add-to-cart-quantity]', 'change', (event) => {
            const condition = !event.target.value || event.target.value === '0' || isNaN(event.target.value) === true;

            event.target
                .closest('[data-shop-add-to-cart-form]')
                .querySelector('[data-ajax-add-to-cart]').dataset.quantity = condition ? '1' : event.target.value;
        });
    }
}

export { initAddToCart, toggleAddToCartLoadingIndicator, toggleMiniCart };
