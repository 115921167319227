/** @module mix-match */

import Vue from 'vue';
import $ from 'jquery';
import { Swiper, Navigation, Lazy, Autoplay, Keyboard } from 'swiper';
import { toggleAddToCartLoadingIndicator, toggleMiniCart } from '../../../shop/molecules/add-to-cart/add-to-cart.async';

Swiper.use([Navigation, Lazy, Autoplay, Keyboard]);

export async function mixAndMatch() {
    const mixAndMatch = document.querySelector('[data-mix-and-match]');

    if (mixAndMatch) {
        const ajaxAddToCartUrl = document.querySelector('[data-ajax-add-to-cart-url]').dataset.ajaxAddToCartUrl,
            defaultProduct = document.querySelectorAll('[data-product-list-item-button]')[0].dataset.productId,
            swiperDelay = 150,
            offCanvasBreakpoints = ['small', 'tiny', 'mobile', 'medium'],
            offCanvasId = document.querySelector('[data-mix-and-match]').dataset.offCanvasId;

        new Vue({
            el: mixAndMatch,
            name: 'MixAndMatch',
            data() {
                return {
                    currentBreakpoint: Foundation.MediaQuery.current,
                    offCanvasBreakpointReached: null,
                    activeSlideIndex: 0,
                    activeProduct: defaultProduct,
                    swiperInstance: null,
                };
            },
            computed: {},
            watch: {
                currentBreakpoint(newCurrentBreakpoint) {
                    this.$nextTick(() => {
                        this.setOffCanvasBreakpointReached(newCurrentBreakpoint);
                    });
                },
                activeProduct() {
                    // wait until DOM is re-rendered before updating variables
                    this.$nextTick(() => {
                        this.initFoundationComponents();

                        this.setActiveProductListClass();

                        // reset slide index
                        this.activeSlideIndex = 0;

                        // add delay to prevent timing issues
                        setTimeout(() => {
                            this.initSingleSwiper();
                        }, swiperDelay);
                    });
                },
            },
            mounted() {
                // init foundation components
                this.initFoundationComponents();

                // wait until DOM is ready to prevent timing issues
                document.onreadystatechange = () => {
                    if (document.readyState === 'complete') {
                        this.initSingleSwiper();
                    }
                };

                // set correct values
                this.setOffCanvasBreakpointReached(this.currentBreakpoint);
                this.setActiveProductListClass();

                window.addEventListener(
                    'resize',
                    () => {
                        this.currentBreakpoint = Foundation.MediaQuery.current;
                    },
                    { passive: true },
                );
            },
            methods: {
                async addToCart(id = '') {
                    const data = new URLSearchParams({
                            action: 'woocommerce_ajax_add_to_cart',
                            product_id: id,
                            product_sku: '',
                            quantity: '1',
                        }),
                        response = await fetch(ajaxAddToCartUrl, {
                            method: 'POST',
                            cache: 'no-cache',
                            credentials: 'same-origin',
                            headers: {
                                'Content-Type': 'application/x-www-form-urlencoded',
                            },
                            body: data.toString(),
                        });

                    return response.json();
                },
                async addMultipleProducts() {
                    const productIds = event.currentTarget.dataset.productIds.split(','),
                        $addMultipleProducts = $(this.$el).find('[data-add-multiple-products]'),
                        requests = [];

                    toggleAddToCartLoadingIndicator(true, $addMultipleProducts);

                    for (let i = 0; i < productIds.length; i++) {
                        requests.push(await this.addToCart(productIds[i]));
                    }

                    const responses = await Promise.all(requests);

                    let hasError = false;

                    Promise.allSettled([responses]).then(([result]) => {
                        for (let val of result.value) {
                            if (val.error === true) {
                                hasError = true;
                                break;
                            }
                        }

                        if (hasError === true) {
                            toggleAddToCartLoadingIndicator(false, $addMultipleProducts);
                        } else {
                            // update wc_fragments
                            $(document.body).trigger('wc_fragment_refresh');
                        }
                    });

                    jQuery('body').on('wc_fragments_refreshed', () => {
                        toggleAddToCartLoadingIndicator(false, $addMultipleProducts);
                        toggleMiniCart();
                    });
                },
                initFoundationComponents() {
                    // init off-canvas to prevent compatibility issues
                    const $offCanvasContainer = $(this.$el).find('[data-off-canvas]'),
                        $offCanvas = new Foundation.OffCanvas($offCanvasContainer, {});
                },
                initSingleSwiper() {
                    const swiper = this.$el.querySelector('.swiper-container[data-enabled="true"]');

                    if (swiper) {
                        this.swiperInstance = new Swiper(swiper, {
                            init: false,
                            loop: true,
                            loopedSlides: 1,
                            centeredSlides: true,
                            slidesPerView: 'auto',
                            watchSlidesProgress: true,
                            watchSlidesVisibility: true,
                            preloadImages: false,
                            lazy: {
                                checkInView: true,
                                loadOnTransitionStart: true,
                                loadPrevNext: true,
                            },
                            autoplay: false,
                            spaceBetween: 0,
                            navigation: {
                                prevEl: this.$el.querySelector('.swiper-button-prev'),
                                nextEl: this.$el.querySelector('.swiper-button-next'),
                            },

                            keyboard: {
                                enabled: true,
                                onlyInViewport: true,
                            },

                            a11y: {
                                enabled: false, // implementation is not ideal, do it manually
                            },

                            on: {
                                init() {
                                    const duplicatedSlides = document.querySelectorAll('.swiper-slide-duplicate');

                                    duplicatedSlides.forEach((slide) => {
                                        slide.setAttribute('aria-hidden', 'true');
                                    });
                                },
                            },
                        });

                        this.swiperInstance.init();

                        // listen on event after initialization to prevent timing issues
                        this.swiperInstance.on('activeIndexChange', (swiper) => {
                            this.activeSlideIndex = swiper.realIndex;
                        });
                    }
                },
                setOffCanvasBreakpointReached(breakpoint) {
                    if (offCanvasBreakpoints.includes(breakpoint)) {
                        // offcanvas
                        this.offCanvasBreakpointReached = true;
                    } else {
                        // popup
                        this.offCanvasBreakpointReached = false;
                    }
                },
                setActiveProductListClass() {
                    const items = document.querySelectorAll('[data-product-list-item-button]'),
                        cssClass = 'mix-and-match__product-selection__list__item__button--active';

                    items.forEach((el) => {
                        if (el.dataset.productId === this.activeProduct) {
                            el.classList.add(cssClass);
                        } else {
                            el.classList.remove(cssClass);
                        }
                    });
                },
                closePopUp() {
                    const popUp = this.$el.querySelector('dialog');

                    popUp.close();
                },
                openOffCanvas() {
                    const offCanvas = $(this.$el).find(`#${offCanvasId}`);

                    offCanvas.foundation('open');
                },
                closeOffCanvas() {
                    const $offcanvas = $(this.$el).find('[data-off-canvas]');

                    $offcanvas.foundation('close');
                },
                setActiveProduct(event) {
                    if (this.$el.querySelector('[data-pop-up][open=""]')) {
                        this.closePopUp();
                    }

                    if (this.$el.querySelector('.off-canvas--mix-and-match.is-open')) {
                        this.closeOffCanvas();
                    }

                    this.activeProduct = event.currentTarget.dataset.productId;
                },
            },
        });
    }
}
