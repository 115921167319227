import { eventListenerOptions } from '@spinnwerk/polyfills';

export function serviceWorker({ register = false, unregister = true } = {}) {
    // handle service workers only in production mode
    if (process.env.NODE_ENV !== 'production') {
        return;
    }

    if (unregister !== true && register === true) {
        if ('serviceWorker' in navigator) {
            window.addEventListener(
                'load',
                () => {
                    navigator.serviceWorker.register('/service-worker.js');
                },
                eventListenerOptions({ passive: true }),
            );
        }
    } else if (unregister !== false) {
        navigator.serviceWorker.getRegistrations().then((registrations) => {
            for (let worker of registrations) {
                worker.unregister();
            }
        });
    }
}
