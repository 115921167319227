/** @module shop */

import { initAddToCart } from './molecules/add-to-cart/add-to-cart.async';
import { shoppingCartMini } from './molecules/shopping-cart-mini/_shopping-cart-mini.async';
import { starRating } from './molecules/ratings/starRating';

/**
 * Initializes shop functionalities.
 *
 * @return {Promise<void>}
 */
export async function shop() {
    initAddToCart();
    shoppingCartMini();
    starRating();
}
