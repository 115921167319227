/** @module shopping-cart-mini */

import { liveEvents } from '../../../../assets/js/application';

/**
 * Display shopping cart mini
 *
 * Should not be called directly.
 * @private
 * @returns {Promise<void>}
 */
export async function shoppingCartMini() {
    const events = await liveEvents(),
        body = document.querySelector('body'),
        shoppingCart = document.querySelector('[data-header-shop-nav-shopping-cart]'),
        shoppingCartIsOpenClass = 'header__shop-nav__shopping-cart--open',
        // set in .breakpointsrc.json
        breakpoints = {
            tablet: 860,
        },
        shoppingCartTouchstartHandler = (event) => {
            // disable shopping-cart anchor & display shopping-mini-cart
            if (event.target.closest('a.shopping-cart')) {
                event.preventDefault();
                shoppingCart.classList.add(shoppingCartIsOpenClass);
            }
        };

    if (shoppingCart) {
        const resizeObserver = new ResizeObserver((entries) => {
            let bodyWidth = entries[0].contentRect.width;

            // show mini-cart on touch devices starting on tablet breakpoint
            if (bodyWidth > breakpoints.tablet) {
                // remove event listener first to prevent adding multiple listeners
                shoppingCart.removeEventListener('touchstart', shoppingCartTouchstartHandler);
                shoppingCart.addEventListener('touchstart', shoppingCartTouchstartHandler);
            } else {
                shoppingCart.removeEventListener('touchstart', shoppingCartTouchstartHandler);
            }
        });

        // start observer
        resizeObserver.observe(body);

        // close shopping-cart-mini on click outside of mini-cart
        document.addEventListener('click', (event) => {
            if (
                event.target !== shoppingCart &&
                !event.target.closest(`.${shoppingCartIsOpenClass}`) &&
                shoppingCart.classList.contains(shoppingCartIsOpenClass)
            ) {
                shoppingCart.classList.remove(shoppingCartIsOpenClass);
            }
        });

        shoppingCart.addEventListener('mouseenter', () => {
            shoppingCart.classList.add(shoppingCartIsOpenClass);
        });

        shoppingCart.addEventListener('mouseleave', () => {
            shoppingCart.classList.remove(shoppingCartIsOpenClass);
        });

        // set loading indicator on item removal
        events.addEventListener('[data-shopping-cart-mini] .remove_from_cart_button', 'click', (event) => {
            event.target.textContent = '';
            event.target.classList.add('remove_from_cart_button--loading');
        });

        // @headroom.js for hiding mini cart on scroll
    }
}
