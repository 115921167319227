/**
 * NOTE: this file only serves as entry point and imports all required functionality from modules.
 * These modules are responsible to check whether they are applicable on the current site and only then load their business logic.
 * This way, we can have the best of both worlds, tree-shaking and dynamic imports.
 *
 * When writing modules, make sure to 'register' them in the corresponding index.js and reference them only over this file.
 *
 * If a module is completely not used on a project, comment the corresponding function calls here.
 */

// breakpoint on which headroom should be active
// NOTE: has to match breakpoint in resources/components/patterns/molecules/headroom/_headroom.scss
const breakpoint = 'small';

import * as application from './application';
// ////////////////////////////////////////////////////////////
// include critical polyfills very early
import * as polyfills from '@spinnwerk/polyfills';
// ////////////////////////////////////////////////////////////
// Framework initialization
import * as foundation from './_foundation';
// ////////////////////////////////////////////////////////////
// Hack vor having full 100vh support on mobile devices
import { fullHeightOnMobile } from './utils/full-height-on-mobile';
// ////////////////////////////////////////////////////////////
// Adds custom classes based on device orientation
// import { deviceOrientation } from './utils/device-orientation';
// ////////////////////////////////////////////////////////////
// Security
import { initSecuringExternalAnchors } from './security/external-anchors';
// optimized font loading
// fonts are defined in app/etc/.fontsrc.json and automatically added to CONFIG
import { loadFonts } from '../../components/base/fonts/fonts';
// update URL and state links based on scrolling
// import { sectionNav } from '../../components/patterns/molecules/navigation';
// ////////////////////////////////////////////////////////////
// form helpers
import * as form from '../../components/patterns/molecules/form';
// ////////////////////////////////////////////////////////////
// atoms
// import { animateSVG } from '../../components/patterns/atoms/animate';
// ////////////////////////////////////////////////////////////
// molecules
import { scrollUp } from '../../components/patterns/molecules/scroll-up/scroll-up';
import { initHeadroom } from '../../components/patterns/molecules/headroom';
// import { fontSizeSwitcher } from '../../components/patterns/molecules/font-size-switcher';
import { initPrimaryNav } from '../../components/patterns/molecules/navigation/primary-nav';
// import { callout } from '../../components/patterns/molecules/callout/callout';
import { cards } from '../../components/patterns/molecules/card/cards';
import { firstLastClasses } from './utils/first-last-classes';
// import { counter } from '../../components/patterns/molecules/counter';
import { filters, searches, mobileFilter } from '../../components/patterns/molecules/filter';
// import { lightbox } from '../../components/patterns/molecules/lightbox';
// ////////////////////////////////////////////////////////////
// layouts
import { masonry } from '../../components/layout/masonry';
import { swiper } from '../../components/layout/swiper';
import { maps } from '../../components/layout/map';
// ////////////////////////////////////////////////////////////
// browser alerts
// import { manageCookieConsent } from '../../components/patterns/molecules/cookie-consent';
// ////////////////////////////////////////////////////////////
// init web share
import { share } from '../../components/patterns/organisms/share';
// ////////////////////////////////////////////////////////////
// init modals
import { popUp } from '../../components/patterns/molecules/pop-up/popUp';
// ////////////////////////////////////////////////////////////
// init shop specific scripts
import { shop } from '../../components/shop';
// ////////////////////////////////////////////////////////////
// init widget specific scripts
import { widgets } from '../../components/widgets';
// ////////////////////////////////////////////////////////////
// init mix-match specific scripts
import { mixAndMatch } from '../../components/patterns/organisms/mix-and-match';

// ////////////////////////////////////////////////////////////
// run bugsnag as early as possible
application.bugsnag();

// ////////////////////////////////////////////////////////////
// add .no-cookie class to <html/> if cookies are disabled
document.documentElement.classList.toggle('no-cookies', navigator.cookieEnabled !== true);

// ////////////////////////////////////////////////////////////
// include critical polyfills very early
polyfills.focusOptions();
polyfills.dialog();

// ////////////////////////////////////////////////////////////
// image lazy loading
import(/* webpackMode: "lazy" */ 'lazysizes');

// enable support for native lazy loading
/* NOTE: native lazy loading has two major downsides:
 * it loads images much earlier than lazysizes when scrolling down and has no possibility to adjust this value
 * and it loads *all* images above or near the 'fold' regardless of their current visibility state (e.g. in sliders/accordions etc).
 * See https://web.dev/native-lazy-loading#how-does-the-loading-attribute-work-with-images-that-are-in-the-viewport-but-not-immediately-visible-(for-example-behind-a-carousel)
 * If your project is fine with this, feel free to enable the following code and remove the above import:
// import lazySizes from 'lazysizes';
// import 'lazysizes/plugins/native-loading/ls.native-loading';
// lazySizes.cfg.nativeLoading = {
//     setLoadingAttribute: true,
//     disableListeners: true, // remove all event listeners added by lazysizes in browsers that support native lazy loading
// };
 */
foundation.init();

fullHeightOnMobile();

// deviceOrientation();

initSecuringExternalAnchors();

// ////////////////////////////////////////////////////////////
// Application setup

// register service worker in production mode
application.serviceWorker({ unregister: true });

loadFonts(CONFIG.fonts);
delete CONFIG.fonts; // release memory, fonts are not needed anymore

// ux / accessibility features
application.handleTabKey();

// init all scrolling belonging
application.autoScrolling({
    fromHashChanges: CONFIG.scrollFromHashChanges,
    offset: {
        small: 0,
        [breakpoint]: {
            up: '.header',
            down: 0,
        },
    },
    getTargetId(hash) {
        return hash.replace(/#\/?/, '');
    },
    beforeScroll(target, $target) {
        /* eslint-disable jquery/no-is, jquery/no-closest */
        // jQuery is required to open targeted Accordion-items anyways, so it is fine to use it */
        if ($target.is('[data-tab-content]')) {
            $target.closest('[data-accordion]').foundation('down', $target);
        }
        /* eslint-enable */
    },
});

// hide elements when others appear
application.clearAway({
    target: '.section-nav',
    trigger: '.footer',
});

// ////////////////////////////////////////////////////////////
// Navigation
// sectionNav({
//     getHashFromElement(el) {
//         return '/' + (el.dataset.sectionNavAnchor || el.id);
//     },
//     getActiveStateSelector(hash) {
//         return `[href="#${hash.replace(/^#?\//, '')}"]`;
//     },
// });

function initFormHelpers() {
    // enable validation
    form.validation().then((parsleyOptions) => {
        form.steps(); // validation needs to be in place before multi-step forms can be initialized

        // automatically attach a loading indicator to submit buttons on form submit
        // but make sure, the validation is attached before
        form.loadingIndicator({
            exclude: '.search-control [type="submit"] button[name="apply_coupon"]',
        });

        // correctly validate shop checkout
        form.shopCheckoutValidation(parsleyOptions);

        // correctly validate forms present on 'my account' page
        form.shopMyAccountValidation(parsleyOptions);
    });

    // enable automatic summaries
    // form.summaries();

    // enable conditional inputs
    // form.conditionals();

    // enable (de-)selecting all checkboxes at once
    form.selectAll();

    // style advanced file inputs
    // form.fileInput({
    //     multipleFilesLabel: ':anz Dateien ausgewählt',
    // });

    // enable multi file uploads
    // form.multiUpload();

    // manage hierarchical checkboxes
    form.hierarchicalCheckboxes();

    // manage disabled states of checkboxes and radiobuttons
    form.radioCheckboxes();

    // init functionality for search-inputs
    form.searchInputs();
}

initFormHelpers();

// WooCommerce replaces DOM completely, so all functionality must be applied again (-> missing event handlers)
document.body.addEventListener('updated_wc_div', () => initFormHelpers(), { passive: true });

// animateSVG();

scrollUp({
    createButton:
        'createScrollUpButton' in CONFIG ? CONFIG.createScrollUpButton : !document.querySelector('.section-nav'),
});

application.globalState.set(
    'headroom',
    initHeadroom({
        // add the spacer only if there is no hero element
        prependSpacerTo: document.querySelector('.wrapper .teaser--hero') ? false : '.off-canvas-content',
        breakpoint,
        offset: {
            small: 0,
            [breakpoint]: {
                up: '[data-headroom-offset]',
                down: 0,
            },
        },
    }),
);

// fontSizeSwitcher();

initPrimaryNav();

// callout();

cards();

// the items in the footer navigation and the items in the container needs to be checked separately
firstLastClasses('.footer__small__nav > *');
firstLastClasses('.footer__small__nav .menu__item');
firstLastClasses('.filter__term', '.filter');

// counter();

filters();
searches();
mobileFilter();

// lightbox();
masonry();

swiper();

maps();

// manageCookieConsent();

share();

// ////////////////////////////////////////////////////////////
// init pop-ups
popUp();

shop();

widgets();

mixAndMatch();

const commentFormButton = document.querySelector('a[href="#commentform"]'); // eslint-disable-line one-var

if (commentFormButton) {
    commentFormButton.addEventListener('click', (event) => {
        event.preventDefault();

        event.target.style.display = 'none';

        document.querySelector('.product-single__reviews__form').style.display = 'block';
    });
}

// ////////////////////////////////////////////////////////////
// global search
document.querySelector('button[data-target="#search"]').addEventListener('click', (event) => {
    event.preventDefault();

    document.querySelector('.global-search').classList.toggle('is-visible');
    document.querySelector('.search-control__input').focus();
});

document.querySelector('.search-control__close').addEventListener('click', (event) => {
    event.preventDefault();

    document.querySelector('.global-search').classList.remove('is-visible');
});

document.onkeydown = (evt) => {
    if (evt.key === 'Escape') {
        document.querySelector('.global-search').classList.remove('is-visible');
    }
};

// ////////////////////////////////////////////////////////////
// loading-indicator for add to cart button
const checkoutButton = document.querySelector('.checkout-button.wc-forward'); // eslint-disable-line one-var

if (checkoutButton) {
    checkoutButton.addEventListener('click', (event) => {
        const button = event.currentTarget;

        if (button.dataset.hasIndicator !== 'true') {
            button.insertAdjacentHTML(
                'beforeend',
                '<span class="button__loading-indicator"><span class="loading-indicator-item"></span></span>',
            );
            button.dataset.hasIndicator = true;
        }

        button.classList.add('is-loading');
    });
}

// ////////////////////////////////////////////////////////////
// save UTM GET parameter to save it later in order meta
let cookie = {},
    expires = '',
    date = new Date();

// check if we have some UTM codes in our GET request
window.location.search
    .substr(1)
    .split('&')
    .forEach((item) => {
        let param = item.split('=');

        if (['utm_campaign', 'utm_medium', 'utm_content', 'utm_source'].includes(param[0])) {
            cookie[param[0]] = decodeURIComponent(param[1]);
        }
    });

// same UTM codes as cookie when present
if (Object.keys(cookie).length) {
    // add extra infos to cookie
    cookie.href = window.location.href;
    cookie.referrer = document.referrer;

    // one year
    date.setTime(date.getTime() + 356 * 24 * 60 * 60 * 1000);
    expires = '; expires=' + date.toUTCString();

    // set cookie
    document.cookie = 'wocommerce_source=' + (JSON.stringify(cookie) || '') + expires + '; path=/';
}
