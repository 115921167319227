/** @module widgets */

/**
 * Initializes widget functionalities.
 *
 * @return {Promise<void>}
 */
export async function widgets() {
    /**
     * Adds load more functionality to blog-tags widget
     *
     * @returns {Promise<void>}
     */
    function blogTagsLoadMore() {
        const blogTagsLoadMoreBtn = document.querySelector('[data-blog-tags-load-more]');

        if (blogTagsLoadMoreBtn) {
            const blogTagsHidden = document.querySelectorAll('[data-blog-tags-hidden]');

            blogTagsLoadMoreBtn.addEventListener('click', (event) => {
                blogTagsHidden.forEach((el) => {
                    delete el.dataset.blogTagsHidden;
                });
                event.target.remove();
            });
        }
    }

    function initWidgets() {
        blogTagsLoadMore();
    }

    initWidgets();
}
